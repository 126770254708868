$(function() {

  setTimeout(function() {
    if (location.hash) {
      /* we need to scroll to the top of the window first, because the browser will always jump to the anchor first before JavaScript is ready, thanks Stack Overflow: http://stackoverflow.com/a/3659116 */
      window.scrollTo(0, 0);
      var scrollTarget = '#' + location.hash.split('#')[1];
      smoothScrollTo($(scrollTarget));
    }
  }, 1);
  
  // taken from: https://css-tricks.com/snippets/jquery/smooth-scrolling/
  $('a.scroll[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      smoothScrollTo($(this.hash));
      return false;
    }
  });
  
  function smoothScrollTo(scrollTarget) {
    scrollTarget = scrollTarget.length ? scrollTarget : $('[name=' + this.hash.slice(1) +']');
    
    if (scrollTarget.length) {
      $('html,body').animate(
        {
          scrollTop: scrollTarget.offset().top - 66
        }, 
        1000, 
        function(){
          $(scrollTarget).find('div.collapse').addClass('show');
        }
      );
    }
  }

});