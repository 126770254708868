$(function () {
  function fileDownload() {
    window.open(atob($('#modalFormDownload').data('index')));
    Cookies.set('file-download-granted', true, { expires: 1 });
  }

  if ($('.wpcf7').length == 1 && $('.modal-form-dowload').length == 1) {
    document.addEventListener( 'wpcf7invalid', function( event ) {
      console.log('Invalid');
    }, false );
    document.addEventListener( 'wpcf7spam', function( event ) {
      console.log('Spam');
    }, false );
    document.addEventListener( 'wpcf7mailfailed', function( event ) {
      fileDownload();
      console.log('Mail fail');
    }, false );
    document.addEventListener( 'wpcf7mailsent', function( event ) {
      fileDownload();
      console.log('Mail sent');
    }, false );  
  }

  $('#modalFormDownload').on('show.bs.modal', function(e) {
    if (! $('#modalFormDownload').data('index')) {
      e.preventDefault();
      alert('Si è everificato un errore nel download del file. Contattare support@lelanet.it per ulteriori informazioni. Grazie.');
      return;
    }

    if (Cookies.get('file-download-granted')) {
      e.preventDefault();
      fileDownload();
    }
  });
});