$(document).ready( function() {
  
  $('.image-text .owl-carousel.manual').owlCarousel({
    'items': 2
  });

  $('.owl-carousel.manual').owlCarousel({
    'autoplay':true,
    'responsive' : {
      0 : {'items' : 1},
      480 : {'items': 2},
      768 : {'items' : 3},
      991 : {'items' : 4}
    }
  });
  
  
  /*
  $('.owl-carousel.owl--dots').owlCarousel({
    dots: true,
    nav: false,
    responsive:{
      0:{
          items:1
      },
      600:{
          items:2
      }
    }
  });
  $('.owl-carousel.owl--nav').owlCarousel({
    dots: false,
    nav: true,
    responsive:{
      0:{
          items:1
      },
      600:{
          items:2
      }
    }
  });
  */
});