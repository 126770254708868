$(function () {
  var $body = $('body');

  if (! Cookies.get('cookies-accepted')) {
    setTimeout(function() {
      $body.addClass('cookie--opened');
    }, 1000);
  }

  $('.cookie-policy__close').on('click', function(e) {
    e.preventDefault();
    Cookies.set('cookies-accepted', true, { expires: 7 });
    $body.removeClass('cookie--opened');
  });
});